<template>
  <div class="partner-wrapper">
    <div class="banner-container">
      <div class="info">
        <h1 class="title">成为魔筷代言人 共享直播电商发展</h1>
        <div class="btn" @click="showQRCodeModal">成为魔筷代言人/合伙人</div>
      </div>
    </div>
    <div class="service-container">
      <h2 class="title">成为魔筷代言人 助力直播电商发展</h2>
      <div class="cards">
        <div v-for="(row, i) in displayServiceData" :key="i" class="cards-row">
          <div
            v-for="(item, index) in row"
            :key="index"
            class="card-item"
            :style="{ backgroundImage: `url(${item.image})` }"
          >
            <span class="text">{{ item.text }}</span>
          </div>
        </div>
        <!-- <div 
          v-for="(item, index) in serviceData"
          :key="index"
          class="card-item"
          :style="{ backgroundImage: `url(${item.image})` }"
        >
          <span class="text">{{item.text}}</span>
        </div> -->
      </div>
      <div class="btn" @click="showQRCodeModal">免费成为魔筷代言人/合伙人</div>
    </div>
    <div class="award-container">
      <h2 class="title">免费成为魔筷代言人，高额奖励等你来拿！</h2>
      <div class="btn" @click="showQRCodeModal">免费成为魔筷代言人/合伙人</div>
    </div>

    <div v-show="qrcodeModalVisible" class="qrcode-modal">
      <div class="qrcode-box">
        <div class="text">成为魔筷代言人/合伙人</div>
        <img
          src="https://mktv-in-cdn.mockuai.com/16593228535907683.png"
          alt=""
          class="qrcode"
        />
        <img
          src="https://mktv-in-cdn.mockuai.com/16254731712693597.png"
          alt=""
          class="close-icon"
          @click="closeQRCodeModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  head: {
    title: '魔筷代言合作-招商加盟-电商直播-直播合作-魔筷星选',
    meta: [
      {
        name: 'Keywords',
        content:
          '服务商代理,服务商入驻,视频号,短视频开店,抖音快手',
      },
      {
        name: 'Description',
        content:
          '【魔筷科技-直播电商服务平台】从代言培训到行业趋势共享以及会员管理等全面赋能,提供抖音快手等直播行业趋势讲解,代言人人设打造等,帮助加盟入驻企业个人直播营销,共享直播电商红利。',
      },
    ],
  },
  data() {
    return {
      qrcodeModalVisible: false,
      serviceData: [
        {
          image: 'https://mktv-in-cdn.mockuai.com/16270298991876082.png',
          text: '直播电商行业趋势培训',
        },
        {
          image: 'https://mktv-in-cdn.mockuai.com/16270298992261725.png',
          text: '魔筷星选会员销讲培训',
        },
        {
          image: 'https://mktv-in-cdn.mockuai.com/16270298992327305.png',
          text: '代言人人设打造培训',
        },
        {
          image: 'https://mktv-in-cdn.mockuai.com/16270298992403228.png',
          text: '代言人TTT讲师培训',
        },
        {
          image: 'https://mktv-in-cdn.mockuai.com/16270298992447882.png',
          text: '会员推荐奖励',
        },
        {
          image: 'https://mktv-in-cdn.mockuai.com/16270298992504032.png',
          text: '卖货指导奖励',
        },
        {
          image: 'https://mktv-in-cdn.mockuai.com/16270298992562487.png',
          text: '续费奖励',
        },
        {
          image: 'https://mktv-in-cdn.mockuai.com/16270298992619304.png',
          text: '年终巨额奖励',
        },
      ],
    }
  },
  computed: {
    displayServiceData() {
      return [this.serviceData.slice(0, 4), this.serviceData.slice(4, 8)]
    },
  },
  methods: {
    showQRCodeModal() {
      this.qrcodeModalVisible = true
    },
    closeQRCodeModal() {
      this.qrcodeModalVisible = false
    },
  },
}
</script>

<style lang="less" scoped>
@import '../style/common.less';

.partner-wrapper {
  .pxToVw(padding-top, 90);
  .banner-container {
    box-sizing: border-box;
    .pxToVw(height, 680);
    .pxToVw(padding-top, 211);
    background-color: #00092a;
    background-image: url(https://mktv-in-cdn.mockuai.com/16270289621293325.png);
    background-size: cover;
    background-repeat: no-repeat;
    .info {
      .pxToVw(margin-left, 240);
      h1.title {
        .pxToVw(margin-bottom, 22);
        line-height: 1.4;
        .pxToVw(font-size, 60);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .desc {
        .pxToVw(margin-bottom, 60);
        .pxToVw(line-height, 33);
        .pxToVw(font-size, 24);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
      .btn {
        .pxToVw(width, 340);
        .pxToVw(height, 60);
        .pxToVw(line-height, 60);
        text-align: center;
        .pxToVw(border-radius, 30);
        background-color: #fff;
        .pxToVw(font-size, 24);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff6600;
        cursor: pointer;
        &:hover {
          background-color: #ff6600;
          color: #fff;
        }
      }
    }
  }
  .service-container {
    box-sizing: border-box;
    .pxToVw(height, 975);
    .pxToVw(padding-top, 92);
    background-color: #fff;
    > .title {
      .pxToVw(margin-bottom, 73);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    .cards {
      margin: 0 auto;
      .pxToVw(width, 1440);
      .cards-row {
        display: flex;
        align-items: center;
        .card-item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 0 0 330 * @unitVw;
          .pxToVw(height, 240);
          .pxToVw(margin-right, 40);
          .pxToVw(margin-bottom, 40);
          .pxToVw(border-radius, 16);
          background-color: #00092a;
          background-size: cover;
          background-repeat: no-repeat;
          &:last-child {
            margin-right: 0;
          }
          .text {
            .pxToVw(font-size, 18);
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
          }
        }
      }
    }
    .btn {
      margin: 54 * @unitVw auto 0;
      .pxToVw(width, 340);
      .pxToVw(height, 60);
      .pxToVw(line-height, 60);
      text-align: center;
      .pxToVw(border-radius, 30);
      background-color: #ff6600;
      .pxToVw(font-size, 24);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
      &:hover {
        font-weight: 600;
        box-shadow: 0px 7px 21px 0px rgba(255, 102, 0, 0.4);
      }
    }
  }
  .market-container {
    box-sizing: border-box;
    .pxToVw(height, 1220);
    .pxToVw(padding-top, 96);
    background: url(https://mktv-in-cdn.mockuai.com/16270318139755493.png) left
        top/cover no-repeat,
      linear-gradient(133deg, #00092a 0%, #001554 100%);
    > .title {
      .pxToVw(margin-bottom, 40);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
    .content {
      position: relative;
      margin: 0 auto 80 * @unitVw;
      .pxToVw(width, 1440);
      .pxToVw(height, 794);
      .main {
        width: 100%;
      }
    }
    .btn {
      margin: 0 auto;
      .pxToVw(width, 340);
      .pxToVw(height, 60);
      .pxToVw(line-height, 60);
      text-align: center;
      .pxToVw(border-radius, 30);
      background-color: #fff;
      .pxToVw(font-size, 24);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ff6600;
      cursor: pointer;
      &:hover {
        font-weight: 600;
      }
    }
  }
  .award-container {
    box-sizing: border-box;
    .pxToVw(height, 291);
    .pxToVw(padding-top, 94);
    background-color: #fff;
    background-image: url(https://mktv-in-cdn.mockuai.com/16270322350365195.png);
    background-size: cover;
    background-repeat: no-repeat;
    > .title {
      .pxToVw(margin-bottom, 15);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    .text {
      .pxToVw(margin-bottom, 60);
      text-align: center;
      .pxToVw(line-height, 112);
      .pxToVw(font-size, 80);
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #333333;
      .num {
        color: #ff6600;
      }
    }
    .btn {
      margin: 0 auto;
      .pxToVw(width, 340);
      .pxToVw(height, 60);
      .pxToVw(line-height, 60);
      .pxToVw(border-radius, 30);
      background-color: #ff6600;
      text-align: center;
      .pxToVw(font-size, 24);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
      &:hover {
        font-weight: 600;
        box-shadow: 0px 7px 21px 0px rgba(255, 102, 0, 0.4);
      }
    }
  }
  .qrcode-modal {
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    .qrcode-box {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      .text {
        .pxToVw(margin-bottom, 25);
        .pxToVw(line-height, 33);
        .pxToVw(font-size, 24);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
      .qrcode {
        .pxToVw(width, 260);
        .pxToVw(height, 260);
        .pxToVw(margin-bottom, 58);
      }
      .close-icon {
        .pxToVw(width, 40);
        .pxToVw(height, 40);
        cursor: pointer;
      }
    }
  }
}
</style>
